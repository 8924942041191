/* eslint-disable axo-frontend/directory-structure */
import { Icons, LoadingSpinner } from '@axo/deprecated/util/ui-components';
import { axoSupportPhoneNumber } from '@axo/insurance/const/no';
import { StepContext } from '@axo/insurance/feature/providers';
import { StorageKeys } from '@axo/shared/auth/crossdomain/StorageKeys';
import { useCrossDomainAuth } from '@axo/shared/auth/crossdomain/useCrossDomainAuth';
import { useInsurancePaymentMandate } from '@axo/shared/data-access/hooks';
import { ReactElement, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from '../../translations/useTranslation';
import styles from './AvtaleGiro.module.scss';

const AvtaleGiroText = () => <span className={styles.bold}>AvtaleGiro</span>;

const Spinner = () => (
  <div className={styles.spinner} role="progressbar">
    <LoadingSpinner colorVariable="--dark-700" />
  </div>
);

const Logo = () => {
  return (
    <div className={styles.logo}>
      <Icons.AvtaleGiro size="full" />
    </div>
  );
};

const AxoSupportPhoneNumber = () => (
  <span className={styles.link}>{axoSupportPhoneNumber}</span>
);

export const AvtaleGiro = (props: {
  onCloseModal?: () => void;
}): ReactElement | null => {
  const { t } = useTranslation();
  const { state } = useContext(StepContext);
  const paymentId = state.steps.verifyPaymentCard.payment?.ID;

  const { authData } = useCrossDomainAuth();

  const payment = useInsurancePaymentMandate(
    paymentId as string,
    false,
    paymentId !== undefined
  );

  useEffect(() => {
    if (!payment?.data?.PaymentURL) return;

    const queryParamsId = new URLSearchParams(window.location.search).get('id');

    const jwt =
      authData?.token ?? sessionStorage.getItem(StorageKeys.Token) ?? undefined;
    const applicationId =
      authData?.applicationId ??
      sessionStorage.getItem(StorageKeys.AppId) ??
      undefined;

    const isMagicToken = applicationId !== queryParamsId;

    localStorage.setItem(
      'avtalegiro',
      JSON.stringify({
        magicToken: isMagicToken ? queryParamsId : undefined,
        applicationId: isMagicToken ? undefined : queryParamsId,
        jwt: jwt ?? undefined,
      })
    );

    window.location.replace(payment.data.PaymentURL);
  }, [payment.data, payment.data?.PaymentURL, authData]);

  const ClickHere = useCallback(
    () => (
      <span className={styles.link} onClick={props.onCloseModal}>
        {t('Here')}
      </span>
    ),
    [props.onCloseModal, t]
  );

  return (
    <div className={styles.avtaleGiro}>
      <span className={styles.title}>{t('Buy insurance')}</span>
      <Logo />
      <Spinner />

      <p className={styles.information}>
        {t('AvtaleGiro Info', [AvtaleGiroText()])}
      </p>
      <p className={styles.information}>
        {t('AvtaleGiro Contact and Support', [
          <ClickHere />,
          <AvtaleGiroText />,
          <AxoSupportPhoneNumber />,
        ])}
      </p>
    </div>
  );
};
